import React, { useState } from 'react';
import './contactMap.css';
import IconMap4 from '../../images/icon--map4.svg';
import IconMap5 from '../../images/icon--map5.svg';
import IconMap6 from '../../images/icon--map6.svg';
import IconSearch from '../../images/icon--search.svg';
import IconChevronDown from '../../images/icon--chevrondown.svg';
import GoogleMapReact from 'google-map-react';

const Marker = ({ text }) => (
  <div className="marker">
    {text}
  </div>
);

export default function ContactMap() {
  const locations = {
    factory: { lat: 6.927079, lng: 79.861244 },
    farm: { lat: 7.290572, lng: 80.633726 },
    office: { lat: 6.9344, lng: 79.8428 }
  };

  const [selectedLocation, setSelectedLocation] = useState('factory');
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [isSortMenuOpen, setIsSortMenuOpen] = useState(false);

  const handleLocationChange = (location) => {
    setSelectedLocation(location);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleSortChange = (order) => {
    setSortOrder(order);
    setIsSortMenuOpen(false);
  };

  const toggleSortMenu = () => {
    setIsSortMenuOpen((prev) => !prev);
  };

  const filteredLocations = Object.keys(locations).filter((location) =>
    location.includes(searchTerm)
  );

  const sortedLocations = filteredLocations.sort((a, b) => {
    if (sortOrder === 'asc') {
      return a.localeCompare(b);
    }
    return b.localeCompare(a);
  });

  return (
    <div>
      <div className="contact-contact-16">
        <div className="contact-content16">
          <div className="contact-section-title">
            <div className="contact-content17">
              <div className="contact-heading7">Find the Premises</div>
              <div className="contact-text">
                You can find the nearest factories, farms, and offices.
              </div>
            </div>
          </div>
          <div className="contact-row2">
            {sortedLocations.includes('factory') && (
              <div className="contact-content20" onClick={() => handleLocationChange('factory')}>
                <img className="contact-icon-map4" alt="loading" src={IconMap4} />
                <div className="contact-contact-info7">
                  <div className="contact-heading">Factory</div>
                </div>
              </div>
            )}
            {sortedLocations.includes('farm') && (
              <div className="contact-content20" onClick={() => handleLocationChange('farm')}>
                <img className="contact-icon-map4" alt="loading" src={IconMap5} />
                <div className="contact-contact-info7">
                  <div className="contact-heading">Farm</div>
                </div>
              </div>
            )}
            {sortedLocations.includes('office') && (
              <div className="contact-content20" onClick={() => handleLocationChange('office')}>
                <img className="contact-icon-map4" alt="loading" src={IconMap6} />
                <div className="contact-contact-info7">
                  <div className="contact-heading">Office</div>
                </div>
              </div>
            )}
          </div>
          <div className="contact-row3">
            <div className="contact-typeleft-icon1">
              <img className="contact-chevron-down-icon" alt="loading" src={IconSearch} />
              <input
                className="contact-type-your-message1"
                type="text"
                placeholder="Search"
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
            <div className="contact-iconfalse1" onClick={toggleSortMenu}>
              <div className="contact-type-your-message1">Sort by</div>
              <img className="contact-chevron-down-icon" alt="loading" src={IconChevronDown} />
            </div>
            {/* {isSortMenuOpen && (
              <div className="sort-menu">
                <div className="sort-option" onClick={() => handleSortChange('asc')}>
                  Ascending
                </div>
                <div className="sort-option" onClick={() => handleSortChange('desc')}>
                  Descending
                </div>
              </div>
            )} */}
          </div>
        </div>
        <div className="map-container">
          <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyBo731CKuuWhNCOmffRhUFEvcxN4IswuNI' }}
            center={locations[selectedLocation]}
            zoom={11}
          >
            <Marker
              lat={locations.factory.lat}
              lng={locations.factory.lng}
              text="Factory"
            />
            <Marker
              lat={locations.farm.lat}
              lng={locations.farm.lng}
              text="Farm"
            />
            <Marker
              lat={locations.office.lat}
              lng={locations.office.lng}
              text="Office"
            />
          </GoogleMapReact>
        </div>
      </div>
    </div>
  );
}
