import React, { useEffect } from 'react'
import Aos from "aos";
import "aos/dist/aos.css";
import "./contactModes.css"
import Envelope from "../../images/icon--envelope.svg"
import IconPhone from "../../images/icon--phone.svg"
import IconMap from "../../images/icon--map.svg"
import IconGroup21 from "../../images/group-21.svg"

export default function ContactModes() {

  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div>
      <div className="contact-modes-contact-21">
            <div className="contact-modes-row">
              <div className="contact-modes-content2" data-aos="fade-right" data-aos-duration="2000">
                <img className="contact-modes-icon-map" alt="loading" src={IconMap} />
                <div className="contact-modes-contact-info">
                  <div className="contact-modes-content3">
                    <div className="contact-modes-heading contact-modes-heading-office">Office</div>
                    <div className="contact-modes-text">199, Kew Road, Colombo 2.</div>
                  </div>
                </div>
              </div>
              <div className="contact-modes-content2" data-aos="fade-up" data-aos-duration="2000">
                <img className="contact-modes-icon-map" alt="loading" src={IconPhone} />
                <img className="contact-modes-icon-message-detail" alt="loading" />
                <div className="contact-modes-contact-info">
                  <div className="contact-modes-content3">
                    <div className="contact-modes-heading contact-modes-heading-phone">Phone</div>
                    <div className="contact-modes-text">
                      <p className="contact-modes-p">+94 112328421</p>
                      <p className="contact-modes-p">+94 112328426</p>
                      <p className="contact-modes-p">+9411 2359359</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="contact-modes-content2" data-aos="fade-up" data-aos-duration="2000">
                <img className="contact-modes-content-child" alt="loading" src={IconGroup21} />
                <img className="contact-modes-icon-message-detail" alt="loading" />
                <div className="contact-modes-contact-info">
                  <div className="contact-modes-content3">
                    <div className="contact-modes-heading contact-modes-heading-fax">Fax</div>
                    <div className="contact-modes-text">+94 112446922</div>
                  </div>
                </div>
              </div>
              <div className="contact-modes-content2" data-aos="fade-left" data-aos-duration="2000">
                <img className="contact-modes-icon-map" alt="loading" src={Envelope} />
                <img className="contact-modes-icon-message-detail" alt="loading" />
                <div className="contact-modes-contact-info">
                  <div className="contact-modes-content3">
                    <div className="contact-modes-heading contact-modes-heading-mail">E-mail</div>
                    <div className="contact-modes-text">
                      <p className="contact-modes-p">cicho@cic.lk</p>
                      <p className="contact-modes-p">info@cic.lk</p>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
    </div>
  )
}
