import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import ContactMainBanner from "../templates/ContactUs/contactMainBanner"
import ContactModes from "../templates/ContactUs/contactModes"
import ContactGetTouch from "../templates/ContactUs/contactGetTouch"
import ContactMap from "../templates/ContactUs/contactMap"


export default function ContactUs() {
  return (
    <div>
      <Header />
      <ContactMainBanner />
      <ContactModes />
      <ContactGetTouch />
      <ContactMap />
      <Footer />
    </div>
  )
}
